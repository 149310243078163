.tableauTableWidget {
  width: 100%;
  border-collapse: collapse;
}

.tableauTableWidgetContainer {
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  padding: 16px;
}

.tableauTableWidget thead tr {
  background: #fff;
  vertical-align: middle;
}

.tableauTableWidget thead tr th {
  padding: 11px 0 11px 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #1f2937;
  vertical-align: middle;
}

.tableauTableWidget tbody tr {
  padding: 0;
  vertical-align: middle;
}

.tableauTableWidget tbody tr:nth-child(odd) {
  background: #f9f9f9;
}

.tableauTableWidget td {
  border: none;
}

.tableauTableWidget tbody tr td {
  padding: 11px 0 11px 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #4b5563;
  vertical-align: middle;
}

.tableauTableWidget thead tr th:first-child,
.tableauTableWidget tbody tr td:first-child {
  width: 25%;
  text-align: left;
}

.expandButton {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  height: 12px;
}

.expandButton span {
  font-size: 16px;
}

.tableauTableWidget td span {
  display: inline-block;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

.tableauTableWidget td button span {
  height: 1rem;
}

.centeredCell {
  text-align: center;
  width: 100%;
  display: inline-block;
}
