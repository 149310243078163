.tableauVizContainer {
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  position: relative;
}

.hidden {
  visibility: hidden;
}
